<template>
  <vs-row>

   <!-- product item list  -->
    <vs-col vs-xs="12" vs-sm="12" vs-lg="8" class="py-4">
      <vx-card class="pb-10">
      <!-- Searching -->
        <vs-row >

          <vs-col vs-w="12" vs-xs="12" class="py-2">
            <vs-row>
              <vs-col vs-w="6" vs-xs="12" class="px-2">
                <span>คำค้นหา</span>
                <vs-input
                  v-model="filter.keyword"
                  placeholder="คำค้นหาชื่อชุด รหัสสินค้า"
                  class="w-full"
                />
              </vs-col>
              <!-- category / texture -->
              <vs-col vs-w="3" vs-xs="12" class="px-2">
                <vs-button
                  color="primary"
                  type="filled"
                  class="btn-filter w-full"
                  @click="searchProduct"
                >
                  ค้นหา
                </vs-button>
              </vs-col>
              <vs-col vs-w="3" vs-xs="12" class="px-2">
                <vs-button
                  color="warning"
                  type="filled"
                  class="btn-filter w-full"
                  @click="resetFilter"
                >
                  รีเซ็ต
                </vs-button>
              </vs-col>
            </vs-row>
          </vs-col>
        </vs-row>
        <vs-row class="py-2">
          <vs-col vs-w="6" vs-xs="12" class="px-2 py-2 div-filter">
            <span>ประเภท</span>
            <multiselect
              v-model="filter.category"
              :options="allInfo.categories"
              :multiple="false"
              :close-on-select="true"
              :hideSelected="true"
              :searchable="true"
              label="description"
              track-by="code"
              selectedLabel=""
              selectLabel=""
              deselectLabel=""
              placeholder=""
              open-direction="bottom"
            ></multiselect>
          </vs-col>
          <vs-col vs-w="6" vs-xs="12" class="px-2 py-2">
            <span>ชนิด</span>
            <multiselect
              v-model="filter.texture"
              :options="allInfo.textures"
              :multiple="false"
              :close-on-select="true"
              :hideSelected="true"
              :searchable="true"
              selectedLabel=""
              label="description"
              track-by="code"
              selectLabel=""
              deselectLabel=""
              placeholder=""
              open-direction="bottom"
            ></multiselect>
          </vs-col>

          <!-- color / size -->

          <vs-col vs-w="6" vs-xs="12" class="px-2 py-2">
            <span>สี</span>
            <multiselect
              v-model="filter.color"
              :options="allInfo.colors"
              :multiple="true"
              :close-on-select="true"
              :hideSelected="true"
              label="description"
              track-by="code"
              :searchable="true"
              selectedLabel=""
              selectLabel=""
              deselectLabel=""
              placeholder=""
              open-direction="bottom"
            ></multiselect>
          </vs-col>
          <vs-col vs-w="6" vs-xs="12" class="px-2 py-2">
            <span>ขนาด</span>
            <multiselect
              v-model="filter.size"
              :options="allInfo.sizes"
              :multiple="true"
              :close-on-select="true"
              :hideSelected="true"
              :searchable="true"
              label="description"
              track-by="code"
              selectedLabel=""
              selectLabel=""
              deselectLabel=""
              placeholder=""
              open-direction="bottom"
            ></multiselect>
          </vs-col>
        </vs-row>
        <vs-row class="px-2 py-2">
          <vs-col vs-xs="10" vs-sm="11" vs-lg="11"></vs-col>
          <vs-col vs-xs="2" vs-sm="2" vs-lg="1">
            <!-- <vs-select v-model="filter.displayItemSelected" class="w-full">
              <vs-select-item
                :key="index"
                :value="item"
                :text="item"
                v-for="(item, index) in filter.displayItems"
              />
            </vs-select> -->

            <v-select style="width: 100%;" v-model="filter.displayItemSelected" :options="filter.displayItems" :select="filter.displayItemSelected"
        ></v-select>

          </vs-col>
        </vs-row>
        <vs-row class="py-4">
          <vs-col vs-xs="10" vs-sm="11" vs-lg="11" class="px-2">
            <vs-pagination
              :total="totalPaginate"
              v-model="currentPaginate"
              class="cursor-pointer"
            ></vs-pagination>
          </vs-col>
          <vs-col vs-xs="2" vs-sm="1" vs-lg="1" class="px-2">
            <vs-input
              readonly
              :value="products.length"
              class="input-display-items w-full"
              style="width: 40px !important"
            />
          </vs-col>
        </vs-row>

        <!-- LIST ของ  PRODUCT -->

        <vs-row vs-justify="left" class="pt-8">
          <!-- <VuePerfectScrollbar class="scroll-area" :settings="settings"> -->
            <vs-col
              type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="3"
              vs-xs="6"
              vs-sm="4"
              vs-lg="4"
              v-for="(product, index) in products"
              :key="index"
              class="px-2"
            >
              <vs-card class="cardx">
                <div slot="media" class="px-4 py-4">
                  <img
                    :src="product.mainImage"

                    height="150"
                    class="object-contain"
                  />
                  <div class="text-center pt-4">
                    <h4>{{ product.name }}</h4>
                    <small>({{ product.code }})</small><br />
                    <small style="font-size: 0.8rem"
                      > {{ getName(allInfo.colors, 'code' , product.color, 'description') }} / {{ getName(allInfo.sizes, 'code' , product.size, 'description') }}</small
                    >
                  </div>
                </div>
                <div class="flex justify-center">
                  <table class="product-catalog-table-detail">
                    <tbody>
                      <tr>
                        <td>ค่าเช่า:</td>
                        <td class="pl-2">
                          {{ product.rentalPrice.toLocaleString() }}
                        </td>
                      </tr>
                      <tr>
                        <td>ค่าประกัน:</td>
                        <td class="pl-2">
                          {{ product.bail.toLocaleString() }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <vs-button
                  color="primary"
                  type="filled"
                  class="btn-filter w-full"
                  style="font-size: 10px"
                  @click="addItemToCart(product)"
                >
                  <font-awesome-icon icon="shopping-cart" class="mr-2" />
                  <span>Add to Cart</span>
                </vs-button>
                <div slot="footer"></div>
              </vs-card>
            </vs-col>
          <!-- </VuePerfectScrollbar> -->
        </vs-row>
      </vx-card>
    </vs-col>

    <!-- CART -->
    <vs-col vs-xs="12" vs-sm="12" vs-lg="4"
      v-if="windowWidth > 900"
      :class="[$store.state.reduceButton==true ? 'reduceBtnTrue' :  'reduceBtnFalse']"
        style="position:fixed; top:15%; left: 68%;  padding-bottom: 100px !important;"
        class="px-4 py-4 mb-12 cart-lg"
        >
      <Cart>
        <template v-slot:card-result>
          <vx-card class="my-4" v-if="cart.cartItems.length > 0"  >
            <div class="card-total-price flex justify-center">

              <table class="cart-table">

                <tbody>
                  <tr class="text-primary">
                    <td>ราคาเช่าสินค้า:</td>
                    <td class="pl-2">
                      {{
                        cart.cartRentalPrice.toLocaleString(
                          "en-US",
                          localeStringConfig
                        )
                      }}
                      บาท
                    </td>
                  </tr>
                  <tr class="text-success">
                    <td>เงินประกัน:</td>
                    <td class="pl-2">
                      {{
                        cart.cartBailPrice.toLocaleString(
                          "en-US",
                          localeStringConfig
                        )
                      }}
                      บาท
                    </td>
                  </tr>
                  <tr>
                    <td>ยอดเงินรวม:</td>
                    <td class="pl-2">
                      {{
                        cart.cartTotalPrice.toLocaleString(
                          "en-US",
                          localeStringConfig
                        )
                      }}
                      บาท
                    </td>
                  </tr>
                </tbody>

              </table>

            </div>
          </vx-card>
        </template>
      </Cart>
    </vs-col>

    <!-- FOR MOBILE -->
    <vs-col vs-xs="12" vs-sm="12" vs-lg="4"
        v-if="windowWidth <= 900"
        style="  padding-bottom: 100px !important;"
        class="px-4 py-4 mb-12 cart-mobile"
        >
      <Cart>
        <template v-slot:card-result>
          <vx-card class="my-4" v-if="cart.cartItems.length > 0"  >
            <div class="card-total-price flex justify-center">

              <table class="cart-table">

                <tbody>
                  <tr class="text-primary">
                    <td>ราคาเช่าสินค้า:</td>
                    <td class="pl-2">
                      {{
                        cart.cartRentalPrice.toLocaleString(
                          "en-US",
                          localeStringConfig
                        )
                      }}
                      บาท
                    </td>
                  </tr>
                  <tr class="text-success">
                    <td>เงินประกัน:</td>
                    <td class="pl-2">
                      {{
                        cart.cartBailPrice.toLocaleString(
                          "en-US",
                          localeStringConfig
                        )
                      }}
                      บาท
                    </td>
                  </tr>
                  <tr>
                    <td>ยอดเงินรวม:</td>
                    <td class="pl-2">
                      {{
                        cart.cartTotalPrice.toLocaleString(
                          "en-US",
                          localeStringConfig
                        )
                      }}
                      บาท
                    </td>
                  </tr>
                </tbody>

              </table>

            </div>
          </vx-card>
        </template>
      </Cart>
    </vs-col>

    <!-- RENTAL DATE PICKER VALIDATION -->
    <!-- <vs-col vs-w="10" class="py-4 rental-date-validation"  style="position:fixed;  z-index: 9999 !important; "
      :style="{
          width: $store.state.reduceButton == true ? '61.2% !important' : '54.7% !important',
        }"
    > -->
    <vs-col
    v-if="windowWidth > 900" vs-w="10" class="py-4 rental-date-validation" :class="[$store.state.reduceButton == true ? 'rental-date-validation-true' : 'rental-date-validation-false' ]"  style="position:fixed;  z-index: 9999 !important;  "
    >
      <vs-card >
        <div
          class="grid grid-flow-col lg:grid-cols-5 sm:grid-cols-4"
        >
          <div
            class="flex flex-auto justify-center items-center my-4"
            style="height: 38px"
          >
            <span style="font-size: 14px">กำหนดวันรับ-คืนชุด</span>
          </div>
          <div style=" " class="col-span-2 flex  justify-center">
            <v-date-picker
              v-model="filter.date"
              :locale="{
                id: 'th',
                firstDayOfWeek: 2,
                masks: { L: 'DD/MM/YYYY' }
              }"
              mode="range"
              :min-date="new Date()"
              class="px-4 my-4"
              style="width: 100%"
            />
          </div>
          <div class="flex flex-auto justify-center">
            <vs-button
              color="success"
              type="filled"
              class="my-4"
              @click="reserveCheck"
            >
              เช็คคิวจอง
            </vs-button>
          </div>
          <div class="flex flex-auto justify-center items-center">
             <vs-col v-if="activeBtn==true" style="padding-left:0;" vs-type="flex" vs-align="center" vs-xs="12" vs-sm="12" vs-lg="12">
              <input class="mr-2"
              type="checkbox"
              v-model="checkRentalList"
              @change="onChangeConfirmSubmit"
            />
              <!-- <vs-checkbox v-model="checkRentalList"> -->
                <span v-if="checkRentalList==true" style="font-weight:bold; color:green; font-size:1rem;">ยืนยันดำเนินการต่อ</span>
                <span v-if="checkRentalList==false"  style="font-weight:bold; color:red; font-size:1rem;">ติดคิว</span>
              <!-- </vs-checkbox> -->
            </vs-col>

          </div>
        </div>
      </vs-card>
    </vs-col>

    <vs-col  v-else  class="py-4 rental-date-validation-true"    style="position:fixed;  z-index: 9999 !important; width: 95% !important; "   >
      <vs-card >
        <div
          class="grid grid-flow-col lg:grid-cols-5 sm:grid-cols-4"
        >
          <div
            class="flex flex-auto justify-center items-center my-4"
            style="height: 38px"
          >
            <span style="font-size: 14px">กำหนดวันรับ-คืนชุด</span>
          </div>
          <div style=" " class="col-span-2 flex  justify-center">
            <v-date-picker
              v-model="filter.date"
              :locale="{
                id: 'th',
                firstDayOfWeek: 2,
                masks: { L: 'DD/MM/YYYY' }
              }"
              mode="range"
              :min-date="new Date()"
              class="px-4 my-4"
              style="width: 100%"
            />
          </div>
          <div class="flex flex-auto justify-center">
            <vs-button
              color="success"
              type="filled"
              class="my-4"
              @click="reserveCheck"
            >
              เช็คคิวจอง
            </vs-button>
          </div>
          <div class="flex flex-auto justify-center items-center">
             <vs-col v-if="activeBtn==true" style="padding-left:0;" vs-type="flex" vs-align="center" vs-xs="12" vs-sm="12" vs-lg="12">
              <input class="mr-2"
              type="checkbox"
              v-model="checkRentalList"
              @change="onChangeConfirmSubmit"
            />
              <!-- <vs-checkbox v-model="checkRentalList"> -->
                <span v-if="checkRentalList==true" style="font-weight:bold; color:green; font-size:1rem;">ยืนยันดำเนินการต่อ</span>
                <span v-if="checkRentalList==false"  style="font-weight:bold; color:red; font-size:1rem;">ติดคิว</span>
              <!-- </vs-checkbox> -->
            </vs-col>

          </div>
        </div>
      </vs-card>
    </vs-col>



      <!-- BEGIN popup คิดเงิน -->
      <vs-popup
        title="รายละเอียดผู้เช่า"
        :active.sync="popupOnlineBillingActive"
        :button-close-hidden="true"
      >
        <vs-row>
          <vs-col vs-w="12">
            <vs-row>
              <vs-col
                vs-w="12"
                vs-sm="2"
                vs-md="2"
                vs-lg="2"
                class="flex justify-center items-center"
              >
                เบอร์ติดต่อ
              </vs-col>
              <vs-col vs-w="10" vs-sm="8" vs-md="8" vs-lg="8">
                <vs-input @keypress.enter="getCustomerInfo(customerInfo.phoneNumber)" v-model="customerInfo.phoneNumber" class="w-full" />
              </vs-col>
              <vs-col vs-w="2" class="flex justify-end items-center">
                <vs-button @click="getCustomerInfo(customerInfo.phoneNumber)" color="success">
                  <font-awesome-icon icon="user" style="color: #ffffff" />
                </vs-button>
              </vs-col>
            </vs-row>
            <vs-row class="mt-4">
              <vs-col
                vs-w="12"
                vs-sm="2"
                vs-md="2"
                vs-lg="2"
                class="flex justify-center items-center"
              >
                ชื่อลูกค้า
              </vs-col>
              <vs-col vs-w="12" vs-sm="8" vs-md="8" vs-lg="8">
                <vs-input v-model="customerInfo.name" class="w-full" />
              </vs-col>
            </vs-row>
            <vs-row class="mt-4">
              <vs-col
                vs-w="12"
                vs-sm="2"
                vs-md="2"
                vs-lg="2"
                class="flex justify-center items-center"
              >
                ที่อยู่
              </vs-col>
              <vs-col vs-w="12" vs-sm="8" vs-md="8" vs-lg="8">
                <vs-input v-model="customerInfo.address" class="w-full" />
              </vs-col>
            </vs-row>

            <vs-row class="mt-4" v-if="modalExpand == 'none'">
              <vs-col vs-w="4" class="text-center">
                <font-awesome-icon icon="coins" />
                แต้มคะแนน <span class="text-xl font-bold">{{this.formatPrice(customerInfo.point)}}</span>
              </vs-col>
              <vs-col vs-w="6" class="text-right">
                มูลค่า Voucher <span class="text-xl font-bold">{{this.formatPrice(customerInfo.voucher)}}</span><br />
                <!-- <span class="text-danger">Expired</span> -->
              </vs-col>
            </vs-row>

            <div :style="{ display: modalExpand }">
              <vs-row class="mt-4">
                <vs-col
                  vs-w="12"
                  vs-sm="2"
                  vs-md="2"
                  vs-lg="2"
                  class="flex justify-center items-center"
                >
                  Line ID
                </vs-col>
                <vs-col vs-w="12" vs-sm="8" vs-md="8" vs-lg="8">
                  <vs-input v-model="customerInfo.lineId" class="w-full" />
                </vs-col>
              </vs-row>
              <vs-row class="mt-4">
                <vs-col
                  vs-w="12"
                  vs-sm="2"
                  vs-md="2"
                  vs-lg="2"
                  class="flex justify-center items-center"
                >
                  วันเกิด
                </vs-col>
                <vs-col vs-w="12" vs-sm="8" vs-md="8" vs-lg="8">
                  <v-date-picker
                    v-model="customerInfo.birthDate"
                    :locale="{
                      id: 'th',
                      firstDayOfWeek: 2,
                      masks: { L: 'DD/MM/YYYY' }
                    }"
                  />
                </vs-col>
              </vs-row>
              <vs-row class="mt-4">
                <vs-col
                  vs-w="12"
                  vs-sm="2"
                  vs-md="2"
                  vs-lg="2"
                  class="flex justify-center items-center"
                >
                  รู้จักได้ยังไง
                </vs-col>
                <vs-col vs-w="12" vs-sm="8" vs-md="8" vs-lg="8">
                  <select
                    v-model="customerInfo.howToKnow"
                    class="w-full px-2 py-3 rounded"
                    style="border: 1px solid #e0e0e0"
                  >
                    <option value="เว็บไซต์">เว็บไซต์</option>
                    <option value="Facebook">Facebook</option>
                    <option value="Google Ads">Google Ads</option>
                    <option value="บอกต่อ">บอกต่อ</option>
                  </select>
                </vs-col>
              </vs-row>
              <vs-row class="mt-4">
                <vs-col vs-w="4" class="text-center">
                  <font-awesome-icon icon="coins" />
                  แต้มคะแนน <span class="text-xl font-bold">{{this.formatPrice(customerInfo.point)}}</span>
                </vs-col>
                <vs-col vs-w="6" class="text-center">
                  มูลค่า Voucher <span class="text-xl font-bold">{{this.formatPrice(customerInfo.voucher)}}</span><br />
                  <span class="text-danger">Expired</span>
                </vs-col>
              </vs-row>
            </div>


            <vs-row class="mt-4">
              <vs-col vs-w="12">
                <div class="flex justify-center items-center">
                  <vs-button
                    icon
                    class="c-box-shadow"
                    style="background-color: transparent !important; color: #cccccc"
                    @click="modalExpandToggle"
                  >
                    <font-awesome-icon
                      :icon="
                        modalExpand == 'none' ? 'chevron-down' : 'chevron-up'
                      "
                      size="lg"
                    />
                  </vs-button>
                </div>
              </vs-col>
            </vs-row>
            <div class="mt-4 mb-8">
              <hr />
            </div>
            <!-- ใช้แต้มคะแนน -->
            <vs-row class="mt-4">
              <vs-col vs-w="12" class="mb-4">
                <vs-row>
                   <!-- ใช้แต้มคะแนน -->
                  <vs-col vs-w="9">
                    <vs-row>
                      <vs-col
                        vs-w="12"
                        vs-sm="4"
                        vs-md="4"
                        vs-lg="4"
                        class="flex justify-center items-center"
                      >
                        ใช้แต้มคะแนน
                      </vs-col>
                      <vs-col v-if="$store.state.auth.currentPackage ==='m' || $store.state.auth.currentPackage ==='l'"  vs-w="12" vs-sm="8" vs-md="8" vs-lg="8">
                        <CurrencyValue style="width:80%;"  v-model="usePoint"></CurrencyValue>
                      </vs-col>
                      <vs-col
                          v-else-if="$store.state.auth.currentPackage ==='s'"
                          vs-type="flex"
                          vs-justify="flex-start"
                          vs-align="center"
                          vs-w="12" vs-sm="8" vs-md="8" vs-lg="8"
                          class="pr-4"
                        >
                          <!-- <h2>* * * * *</h2> -->
                          <div style="text-align:center; width:100%; height:100%; padding-top: 7px; padding-bottom: 7px; border-radius:5px;" class="px-1 label-btn-yellow">อัพเกรด Package</div>

                        </vs-col>
                    </vs-row>
                  </vs-col>
                  <vs-col vs-w="3">
                     <span class="pr-4">คงเหลือ</span>
                    <span v-if="$store.state.auth.currentPackage ==='m' || $store.state.auth.currentPackage ==='l'" class="text-xl font-bold ml-4">{{this.formatPrice(customerInfo.point)}}</span>
                    <h3 v-else-if="$store.state.auth.currentPackage ==='s'" >* * * * * </h3>
                  </vs-col>
                </vs-row>
              </vs-col>
              <!-- ใช้ Voucher -->
              <vs-col vs-w="12" class="mb-4">
                <vs-row>
                  <vs-col vs-w="9">
                    <vs-row>
                      <vs-col
                        vs-w="12"
                        vs-sm="4"
                        vs-md="4"
                        vs-lg="4"
                        class="flex justify-center items-center"
                      >
                        ใช้ Voucher
                      </vs-col>
                      <vs-col  v-if="$store.state.auth.currentPackage ==='m' || $store.state.auth.currentPackage ==='l'"  vs-w="12" vs-sm="8" vs-md="8" vs-lg="8">
                         <CurrencyValue style="width:80%;" v-model="useVoucher"></CurrencyValue>
                      </vs-col>
                      <vs-col
                        v-else-if="$store.state.auth.currentPackage ==='s'"
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="12" vs-sm="8" vs-md="8" vs-lg="8"
                        class="pr-4"
                      >
                        <!-- <h2>* * * * *</h2> -->
                        <div style="text-align:center; width:100%; height:100%; padding-top: 7px; padding-bottom: 7px; border-radius:5px;" class="px-1 label-btn-yellow">อัพเกรด Package</div>
                      </vs-col>

                    </vs-row>
                  </vs-col>
                  <vs-col vs-w="3">
                     <span class="pr-4">คงเหลือ</span>
                    <span v-if="$store.state.auth.currentPackage ==='m' || $store.state.auth.currentPackage ==='l'" class="text-xl font-bold ml-4">{{this.formatPrice(customerInfo.voucher)}}</span>
                    <h3 v-else-if="$store.state.auth.currentPackage ==='s'" >* * * * * </h3>
                  </vs-col>
                </vs-row>
              </vs-col>
              <vs-col vs-w="12" class="mb-4">
                <vs-row>
                  <vs-col vs-w="9">
                    <vs-row>
                      <vs-col
                        vs-w="12"
                        vs-sm="4"
                        vs-md="4"
                        vs-lg="4"
                        class="flex justify-center items-center"
                      >
                        ส่วนลดเงินประกัน
                      </vs-col>
                      <vs-col  vs-w="12" vs-sm="8" vs-md="8" vs-lg="8">
                         <CurrencyValue  id="bailDiscount" class="w-full" v-model="bailDiscount"></CurrencyValue>
                      </vs-col>
                    </vs-row>
                  </vs-col>

                </vs-row>
              </vs-col>
              <vs-col vs-w="12" class="mb-4">
                <vs-row>
                  <vs-col vs-w="9">
                    <vs-row>
                      <vs-col
                        vs-w="12"
                        vs-sm="4"
                        vs-md="4"
                        vs-lg="4"
                        class="flex justify-center items-center"
                      >
                        ส่วนลดค่าเช่า
                      </vs-col>
                      <vs-col vs-w="12" vs-sm="8" vs-md="8" vs-lg="8">
                        <CurrencyValue class="w-full" v-model="rentalDiscount"></CurrencyValue>
                      </vs-col>
                    </vs-row>
                  </vs-col>

                </vs-row>
              </vs-col>
              <vs-col vs-w="12" class="mb-4">
                <vs-row>
                  <vs-col vs-w="9">
                    <vs-row>
                      <vs-col
                        vs-w="12"
                        vs-sm="4"
                        vs-md="4"
                        vs-lg="4"
                        class="flex justify-center items-center mb-4 pb-4"
                      >
                        ส่วนลดโปรโมชั่น
                      </vs-col>
                      <vs-col

                      v-if="$store.state.auth.currentPackage ==='m' || $store.state.auth.currentPackage ==='l'"
                        vs-w="12"
                        vs-sm="8"
                        vs-md="8"
                        vs-lg="8"
                        class="mb-4"
                      >
                        {{formatPrice2Point(promotionDiscount)}} ({{promotionDiscountDesc}})
                        <!-- <CurrencyValue class="w-full" v-model="promotionDiscount"></CurrencyValue> -->
                      </vs-col>
                       <vs-col
                        v-else-if="$store.state.auth.currentPackage ==='s'"
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="12" vs-sm="8" vs-md="8" vs-lg="8"
                        class="pr-4 mb-4"
                      >
                      <!-- <h2>* * * * *</h2> -->
                      <div style="text-align:center; width:100%; height:100%; padding-top: 7px; padding-bottom: 7px; border-radius:5px;" class="px-1 label-btn-yellow">อัพเกรด Package</div>
                    </vs-col>
                      <vs-col
                        vs-w="12"
                        vs-sm="4"
                        vs-md="4"
                        vs-lg="4"
                        class="flex justify-center items-center"
                      >
                        วิธีมารับ
                      </vs-col>
                      <vs-col
                        vs-w="12"
                        vs-sm="8"
                        vs-md="8"
                        vs-lg="8"
                        class="mb-4"
                      >
                        <select
                          v-model="pickupMethod"
                          class="px-2 py-3 rounded"
                          style="border: 1px solid #e0e0e0; width: 200px"
                        >
                          <option
                            v-for="(item, index) in walkinOptions"
                            :key="`option-walkin-${index}`"
                            :value="item"
                            >{{ item }}</option
                          >
                        </select>
                      </vs-col>

                      <vs-col
                        vs-w="12"
                        vs-sm="4"
                        vs-md="4"
                        vs-lg="4"
                        class="flex justify-center items-center"
                      >
                        วิธีชำระค่าเช่า
                      </vs-col>
                      <vs-col
                        vs-w="12"
                        vs-sm="8"
                        vs-md="8"
                        vs-lg="8"
                        class="mb-4"
                      >

                        <select v-model="rentalPaymentMethod" class="px-2 py-3 rounded"
                          style="border: 1px solid #e0e0e0; width: 200px" >
                          <option
                            v-for="(item, index) in rentalPaymentOptions"
                            :key="`option-rentalpayment-${index}`"
                            :value="item"
                            >{{ item }}</option
                          >
                        </select>
                      </vs-col>

                      <vs-col
                        vs-w="12"
                        vs-sm="4"
                        vs-md="4"
                        vs-lg="4"
                        class="flex justify-center items-center"
                      >
                        วิธีชำระค่าประกัน
                      </vs-col>
                      <vs-col
                        vs-w="12"
                        vs-sm="8"
                        vs-md="8"
                        vs-lg="8"
                        class="mb-4"
                      >
                        <select v-model="bailPaymentMethod"  class="px-2 py-3 rounded"
                          style="border: 1px solid #e0e0e0; width: 200px" >


                          <option
                            v-for="(item, index) in bailPaymentOptions"
                            :key="`option-bailpayment-${index}`"
                            :value="item"
                            >{{ item }}</option
                          >
                        </select>
                      </vs-col>
                        <vs-col
                        vs-w="12"
                        vs-sm="4"
                        vs-md="4"
                        vs-lg="4"
                        class="mb-4  flex justify-center items-center"
                        >วันรับ ~ คืนชุด</vs-col>
                        <vs-col
                          vs-w="12"
                        vs-sm="8"
                        vs-md="8"
                        vs-lg="8"
                        class="mb-4 flex items-center"

                        >
                          <b>{{ format_ddmmyyy(startDate) }} ~ {{ format_ddmmyyy(endDate) }}</b>
                        </vs-col>

                      <vs-col
                        vs-w="12"
                        vs-sm="4"
                        vs-md="4"
                        vs-lg="4"
                        class="flex justify-center items-center"
                      >
                        โน้ต
                      </vs-col>
                      <vs-col vs-w="12" vs-sm="8" vs-md="8" vs-lg="8">
                        <vs-textarea v-model="note" />
                      </vs-col>

                      <vs-col vs-w="12" class="mt-8">
                        <vs-row>
                          <vs-col vs-w="12" vs-sm="4" vs-md="4" vs-lg="4">
                          </vs-col>

                          <vs-col vs-w="12" vs-sm="4" vs-md="4" vs-lg="4">
                            <vs-button
                              style="margin:10px;" color="danger" type="border"
                              :disabled="btnActive"
                              @click="closePopupOnlineBilling"
                            >
                              ยกเลิก
                            </vs-button>
                          </vs-col>

                          <vs-col vs-w="12" vs-sm="4" vs-md="4" vs-lg="4">
                            <vs-button   style="margin:10px; width:150px;" color="success" type="filled"
                                      @click="prepareSubmitOrder()" :disabled="btnActive" >
                              ตกลง
                            </vs-button>
                          </vs-col>


                        </vs-row>
                      </vs-col>
                    </vs-row>
                  </vs-col>
                </vs-row>
              </vs-col>
            </vs-row>
          </vs-col>
        </vs-row>
      </vs-popup>

  </vs-row>
</template>

<script>

import axios from "axios";
import Cart from "../../../../components/backoffice/Cart.vue";
import Multiselect from "vue-multiselect";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import shapeFormat from "@/mixins/shapeFormat.js";
import Datepicker from "vuejs-datepicker";
import CurrencyValue from "@/views/wintershopback/Components/CurrencyValue.vue";
import CurrencyValueWithEvent from "@/views/wintershopback/Components/CurrencyValueWithEvent.vue";

import vSelect from "vue-select";

export default {
  mixins: [shapeFormat],
  computed: {
    getReduceButton(){
      return  this.$store.state.reduceButton;
    },
    getCurrentBranch(){
      return this.$store.getters["auth/currentBranch"];
    },
    checkRentalList: {
      set(){},
      get() {
        return this.$store.getters["cart/checkRentalList"];
      }
    },
    activeBtn: {
      set(){},
      get() {
        return this.$store.getters["cart/activeBtn"];
      }
    },
    cart: {
      get() {
        return this.$store.state.cart;
      }
    }
  },
  components: { Multiselect, Cart, VuePerfectScrollbar ,Datepicker, CurrencyValue,CurrencyValueWithEvent, "v-select": vSelect,},
  data() {
    return {
      windowWidth: window.innerWidth,
      widthText: '',
      // activeBtn: false,
      btnActive: false,
      // checkRentalList: false,

      customerInfo: {
        phoneNumber: '',
        name: '',
        address: '',
        lineId: '',
        birthDate: new Date('1800-01-01'),
        howToKnow: '',
        point: 0,
        voucher: 0,
        insuranceDiscount: '',
        insuranceDiscountPercent: '',
        rentalDiscount: '',
        rentalDiscountPercent: '',
        promotionDiscount: 0,
        promotionDiscountDesc: 0,


        paymentInsuranceType: '',
      },
      startDate: null,
      endDate: null,
      usePoint: 0,
      useVoucher: 0,
      bailDiscount: 0,
      rentalDiscount: 0,
      promotionDiscount: 0,
      pickupMethod: '',
      rentalPaymentMethod: '',
      bailPaymentMethod: '',

      allInfo: {
        categories: [],
        colors: [],
        sizes: [],
        textures: []
      },
      products: [],
      totalPaginate: 0,
      currentPaginate: 1,
      pagination: {},
      productTotal: 0,
      popupOnlineBillingActive: false,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      },
      filter: {
        date: null,
        keyword: "",
        color: [],
        size: [],
        category: "",
        texture: "",
        displayItems: [15, 30, 45],
        displayItemSelected: 15
      },
      options: ["item1", "item2", "item3"],
      walkinOptions: ["มารับเอง", "Line Man", "Grab", "Kerry", "Flash", "ขนส่งอื่น"],
      rentalPaymentOptions: ["ยังไม่ชำระ","เงินสด", "โอนเงิน" , "Alipay/wechat"  , "บัตรเครดิต","ช่องทางอื่น"],
      bailPaymentOptions: ["ยังไม่ชำระ","เงินสด", "โอนเงิน"  , "Alipay/wechat" , "บัตรเครดิต","ช่องทางอื่น"],
      knowSelected: 1,
      walkinSelected: "เดินทางมารับเอง",
      // bailPaymentMethod: "",
      birthdate: "",
      modalExpand: "none",
      note: "",
      localeStringConfig: {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      },
      confirmSubmit: false,
      branchId: "",
      disableConfirmCheckbox: true
    };
  },
  beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
  methods: {


    async calculatePromotionDiscount(){


              if(this.usePoint === "" || this.usePoint === undefined){
                this.usePoint = 0;
              }
              if(this.useVoucher === "" || this.useVoucher === undefined){
                this.useVoucher = 0;
              }

              if(this.rentalDiscount === "" || this.rentalDiscount === undefined){
                this.rentalDiscount = 0;
              }
              if(this.bailDiscount === "" || this.bailDiscount === undefined){
                this.bailDiscount = 0;
              }

          let reqOrderDetail = {
                  // "wknOrderId" : "R001",
                  // "orderNumber" : null,
                  branchId: this.getCurrentBranch.branchId,
                  // orderStatus: orderStatus,
                  customerId: "",
                  customerName: this.customerInfo.name,
                  customerAddress: this.customerInfo.address,
                  customerPhone: this.customerPhone,
                  lineId: this.customerInfo.lineId,
                  birthDate: new Date(this.$dateFormat(this.customerInfo.birthDate)),
                  howToKnowShop: this.customerInfo.howToKnow,
                  accountNumber: "",
                  accountName: "",
                  bankName: "",
                  startDate: this.$dateFormat(this.startDate),
                  endDate: this.$dateFormat(this.endDate),
                  pickupDate: this.$dateFormat(this.startDate),
                  returnDate: this.$dateFormat(this.endDate),
                  receivingMethod:  this.pickupMethod,
                  // paymentMethod: โอนเงิน,
                  remark1: this.remark1,
                  rentalPaymentMethod: this.rentalPaymentMethod,
                  bailPaymentMethod: this.bailPaymentMethod,
                  pointDiscount: this.usePoint,
                  voucherDiscount: this.useVoucher,
                  bailDiscount: this.bailDiscount,
                  rentalDiscount: this.rentalDiscount,
                  useFreeItemPromotion: true,
                  useDiscountPromotion: true
              };


              let reqOrderItems = [];

              //***BEGIN fetch data from cart */
              if (this.cart.cartItems.length > 0) {
                  this.cart.cartItems.forEach(item => {
                    const data = {
                      productItemId: item.info.productItemId,
                      reservedQuantity: item.info.quantity
                    };
                    reqOrderItems.push(data);

                  });
                }

              //***END  fetch data from cart */

              // let req = { orderDetail: reqOrderDetail,
              //         orderItems: reqOrderItems
              //       };

              //***  21Jan2022
              try{
                let res = await this.calPromotionDiscount(reqOrderDetail , reqOrderItems);
                this.promotionDiscount = res.promotionDiscount;
                this.promotionDiscountDesc =  res.discountSetting;

                // console.log("this.promotionDiscount >>> ",this.promotionDiscount, "this.bailDiscount >>> ",this.bailDiscount, "this.rentalDiscount >>> ",this.rentalDiscount);
                // console.log("this.usePoint >>> ",this.usePoint, "this.useVoucher >>> ",this.useVoucher);

              }catch(ex){
                this.promotionDiscount = 0;
                // this.bailDiscount = 0;
                // this.rentalDiscount = 0;
                this.promotionDiscountDesc =  '';
              }


              // console.log("api working",res);



    },

    async prepareSubmitOrder(){

            let req = null;

            let res = null;
            if(this.startDate === null || this.startDate === null){
                this.$swal({
                  icon: 'warning',
                  title: 'ยังไม่ได้กำหนดวันรับ-วันคืนชุด',
                });

                return;
              }


              if (this.formatDate(this.startDate) > this.formatDate(this.endDate)) {
                this.noticeWarning(
                  "ข้อมูลไม่ถูกต้อง",
                  "การกำหนดวันรับชุดต้องอยู่ก่อนวันคืนชุด !!!"
                );

                return;
              }

              if (this.customerInfo.point < this.usePoint) {
                this.$swal({
                    icon: 'warning',
                    title: 'Point มีไม่พอ',
                    text: 'Point ที่ใช้ ' + this.usePoint +  ' ลูกค้ามี Point: ' + this.customerInfo.point
                  });



                return;
              }

              if (this.customerInfo.voucher < this.useVoucher) {
                this.$swal({
                    icon: 'warning',
                    title: 'Voucher มีไม่พอ',
                    text: 'Voucher ที่ใช้ ' + this.useVoucher  + ' ลูกค้ามี Voucher:  ' + this.customerInfo.voucher
                  });

                return;
              }

              if (!this.checkCustomerInfo()) {
                return;
              }

              this.$vs.loading();
              this.btnActive = true;

              if(this.usePoint === "" || this.usePoint === undefined){
                this.usePoint = 0;
              }
              if(this.useVoucher === "" || this.useVoucher === undefined){
                this.useVoucher = 0;
              }

              if(this.rentalDiscount === "" || this.rentalDiscount === undefined){
                this.rentalDiscount = 0;
              }
              if(this.bailDiscount === "" || this.bailDiscount === undefined){
                this.bailDiscount = 0;
              }



            let reqOrderDetail = {
                  // "wknOrderId" : "R001",
                  // "orderNumber" : null,
                  branchId: this.getCurrentBranch.branchId,
                  // orderStatus: orderStatus,
                  customerId: "",
                  customerName: this.customerInfo.name,
                  customerAddress: this.customerInfo.address,
                  customerPhone: this.customerInfo.phoneNumber,
                  lineId: this.customerInfo.lineId,
                  birthDate: new Date(this.$dateFormat(this.customerInfo.birthDate)),
                  howToKnowShop: this.customerInfo.howToKnow,
                  accountNumber: '',
                  accountName: '',
                  bankName: '',
                  startDate: this.$dateFormat(this.filter.date.start),
                  endDate: this.$dateFormat(this.filter.date.end),
                  receivingMethod:  this.pickupMethod,
                  // paymentMethod: โอนเงิน,
                  remark1: this.note,
                  rentalPaymentMethod: this.rentalPaymentMethod,
                  bailPaymentMethod: this.bailPaymentMethod,
                  pointDiscount: this.usePoint,
                  voucherDiscount: this.useVoucher,
                  bailDiscount: this.bailDiscount,
                  rentalDiscount: this.rentalDiscount,
                  useFreeItemPromotion: true,
                  useDiscountPromotion: true
              };

              let reqOrderItems = [];

              //***BEGIN fetch data from cart */
              if (this.cart.cartItems.length > 0) {
                  this.cart.cartItems.forEach(item => {
                    const data = {
                      productItemId: item.info.productItemId,
                      reservedQuantity: item.info.quantity
                    };
                    reqOrderItems.push(data);

                  });
                }

              //***END  fetch data from cart */

              req = { orderDetail: reqOrderDetail,
                      orderItems: reqOrderItems
                    };



              try{
                res = await this.submitOrder(req);
              }
              catch(err){
                this.$swal({
                    icon: 'warning',
                    title: 'ไม่สามารถบันทึกข้อมูลได้',
                  });
                  this.btnActive = false;
                  this.$vs.loading.close();
                  return;
              }

              if(res == null)
              {
                this.$swal({
                    icon: 'warning',
                    title: 'ไม่สามารถบันทึกข้อมูลได้',
                  });
                  this.btnActive = false;
                  this.$vs.loading.close();
                  return;
              }



              if(res.data.success == true)
              {

                this.closePopupOnlineBilling();
                this.$store.commit("cart/resetCart", false);

                  this.$router.push({
                  path: "/backoffice/order-detail/" + res.data.orderHeaderId
                });
                this.btnActive = false;
                this.$vs.loading.close();
              }

              this.btnActive = false;
              this.$vs.loading.close();





    },
    checkCustomerInfo() {
      // >>>>>>>>>> Validate INFO
      if (
        this.customerInfo.name === null ||
        this.customerInfo.name === undefined ||
        this.customerInfo.name === ""
      ) {
        this.noticeWarning("ข้อมูลไม่ครบ", "ยังไม่ได่ใส่ชื่อลูกค้า");
        return false;
      }
      if (
        this.customerInfo.address === null ||
        this.customerInfo.address === undefined ||
        this.customerInfo.address === ""
      ) {
        this.noticeWarning("ข้อมูลไม่ครบ", "ยังไม่ได่ใส่ที่อยู่ลูกค้า");
        return false;
      }
      if (
        this.customerInfo.phoneNumber === null ||
        this.customerInfo.phoneNumber === undefined ||
        this.customerInfo.phoneNumber === ""
      ) {
        this.noticeWarning("ข้อมูลไม่ครบ", "ยังไม่ได่ใส่เบอร์โทรลูกค้า");
        return false;
      }

      return true;

      // >>>>>>>>>> Validate INFO
    },

    async getCustomerInfo(phoneNumber){

      let cust = null;
      try{
        cust = await this.checkCustomerByPhoneNumber(phoneNumber);

      }catch(err){
        // this.$swal.fire({
        //     icon: 'warning',
        //     title: 'ไม่มีข้อมูลลูกค้าในฐานข้อมูล',
        //     // text: 'Something went wrong!',
        //   });
        return;
      }
      this.customerInfo.name = cust.customer.name;
      this.customerInfo.address = cust.customer.address;
      this.customerInfo.birthDate = new Date(this.formatDate(cust.customer.birthDate));
      this.customerInfo.lineId = cust.customer.lineId;
      this.customerInfo.howToKnow = cust.customer.howToKnowShop;
      this.customerInfo.voucher =   cust.maxVoucher;
      this.customerInfo.point =   cust.maxPoint;

    },
    closePopupOnlineBilling() {
      this.popupOnlineBillingActive = false;
      this.$store.commit("cart/checkRentalListInput", false);
      this.confirmSubmit = !this.confirmSubmit;
      this.customerInfo= {
        phoneNumber: '',
        name: '',
        address: '',
        lineId: '',
        birthDate: '',
        howToKnow: '',
        point: 0,
        voucher: 0,
        bailDiscount: '',
        bailDiscountPercent: '',
        rentalDiscount: '',
        rentalDiscountPercent: '',
        promotionDiscount: '',
        pickupMethod: '',
        paymentType: 'เงินสด',
        paymentInsuranceType: '',
        note: ''
      }
    },
    reserveCheck() {
      // console.log(`>>> reserve check`);

      this.customerInfo = {
        phoneNumber: '',
        name: '',
        address: '',
        lineId: '',
        birthDate: new Date('1800-01-01'),
        howToKnow: '',
        point: 0,
        voucher: 0,
        insuranceDiscount: '',
        insuranceDiscountPercent: '',
        rentalDiscount: '',
        rentalDiscountPercent: '',
        promotionDiscount: 0,
        promotionDiscountDesc: 0,


        paymentInsuranceType: '',
      }

      let filterDate = this.filter.date;
      const state = this.$store.state;

      if (filterDate === null) {
        this.$swal({
          icon: "info",
          title: "กรุณาเลือกวันกำหนดรับ-คืนชุด"
        });
      } else {
        if (state.cart.cartItems.length === 0) {
          this.$swal({
            icon: "info",
            title: "กรุณาเลือกสินค้าใส่ตะกร้า"
          });
        } else {
          const pickupDate = this.$dateFormat(filterDate.start);
          const returnDate = this.$dateFormat(filterDate.end);
          console.log(pickupDate, returnDate);

          let products = [];
          state.cart.cartItems.forEach(item => {
            products.push({
              productItemId: item.id,
              pickupDate: pickupDate,
              returnDate: returnDate,
              branchId: state.auth.currentBranch.branchId,
              qty: item.info.quantity
            });
          });

          const request = this.$customAxios(
            "POST",
            `${state.apiURL}/api/orderdetail/checkq`,
            products,
            state.auth.apiHeader
          );

          request.then(res => {
            // console.log(`>>> res checkq`, res);
            let valid = true;

            // console.log("res >>> ",res);

            this.$store.commit("cart/triggerProductNotAvailable", res);

            //  console.log("res >>> ",res);

            // res.forEach(item => {
            //   if (item.result === 0) {
            //     valid = false;
            //     this.$store.dispatch("cart/triggerProductNotAvailable", {
            //       id: item.productItemId
            //     }
            //     );
            //   }
            // });
            // console.log("this.cartItems >>> ",this.cart.cartItems);

            this.cart.cartItems.forEach( item => {
                  if (item.info.notAvailable == true) {
                     valid = false;
                  }
              }
            );


            if (valid) {
              // this.disableConfirmCheckbox = false;
              this.$store.commit("cart/checkRentalListInput", true);
              this.$store.commit("cart/activeBtnInput", true);
              this.popupOnlineBillingActive = true;
              // console.log("checkRentalList >>> ",this.checkRentalList , " activeBtn >>> ",this.activeBtn);
              this.calculatePromotionDiscount();

            }else{
              this.$store.commit("cart/activeBtnInput", true);
              this.$store.commit("cart/checkRentalListInput", false);
              // console.log("checkRentalList >>> ",this.checkRentalList , " activeBtn >>> ",this.activeBtn);
            }
          });
        }
      }
    },

    searchProduct() {
      // console.log(`>>> searchProduct`);
      const url = `${this.$store.state.apiURL}/api/v2/GetProductItems`;

      let filterColor = "";
      let filterSize = "";

      this.filter.color.forEach(color => {
        filterColor += `${color.code}|`;
      });

      this.filter.size.forEach(size => {
        filterSize += `${size.code}|`;
      });


      let filterCategory = "";
      let filterTexture = "";

      if(this.filter.category !== null && this.filter.category !== ""){
        filterCategory = this.filter.category.code;
      }

      if(this.filter.texture !== null && this.filter.texture !== ""){
        filterTexture = this.filter.texture.code;
      }


      const dateSelected = this.filter.date;
      let dateFormat = {
        start: {
          format: ""
        },
        end: {
          format: ""
        }
      };

      if (dateSelected !== null) {
        const startDate = new Date(dateSelected.start);
        const endDate = new Date(dateSelected.end);
        dateFormat = {
          start: {
            year: startDate.getFullYear(),
            month: startDate.getMonth() + 1,
            day: startDate.getDate()
          },
          end: {
            year: endDate.getFullYear(),
            month: endDate.getMonth() + 1,
            day: endDate.getDate()
          }
        };

        dateFormat.start.format = `${dateFormat.start.year}-${dateFormat.start.month}-${dateFormat.start.day}`;
        dateFormat.end.format = `${dateFormat.end.year}-${dateFormat.end.month}-${dateFormat.end.day}`;
      }

      const data = {
        filter: true,
        showNotAvailable: false,
        branchId: this.getCurrentBranch.branchId,
        keyword: this.filter.keyword, // ค้นหาชื่อ OR code
        size: filterSize,
        color: filterColor,
        category: filterCategory,
        texture: filterTexture,
        simpleSearch: false,
        pageNumber: 1,
        pageSize: this.filter.displayItemSelected
      };

      // console.log(`>>> search req`, data);

      const state = this.$store.state;
      const request = this.$customAxios(
        "POST",
        url,
        data,
        state.auth.apiHeader
      );

      request.then(res => {
        const data = res;
        // get total items
        this.totalPaginate = Math.round(
          data.itemNumber / this.filter.displayItemSelected
        ) + 1;

        // fresh display items
        this.products = [];

        // update path image
        data.itemList.forEach((item, index) => {
          // console.log(item, index);
          if (data.itemList[index].mainImage !== null) {
            const imgURL = `${this.$store.state.imageStore}${item.mainImage}`;
            data.itemList[index]["mainImage"] = imgURL;
            // console.log(imgURL);
          } else {
            data.itemList[index]["mainImage"] = this.$store.state.defaultImage;
          }
        });

        this.currentPaginate = 1;

        this.products = data.itemList;

        this.pagination = this.$calcPagination(
          data.itemList.length,
          this.currentPaginate,
          data.itemListNumber,
          data.itemNumber
        );
      });
    },
    addItemToCart(item) {
      this.$store.dispatch("cart/addCartItem", {
        id: item.productItemId,
        info: {
          ...item,
          price: item.rentalPrice,
          bail: item.bail
        }

      });
      this.checkRentalList = false;
      this.noticeSuccess("ใส่ข้อมูลลงตะกร้าแล้ว");
    },
    resetFilter() {
      this.filter = {
        date: null,
        keyword: "",
        color: [],
        size: [],
        category: "",
        texture: "",
        displayItems: [15, 30, 60],
        displayItemSelected: 15
      };

      this.fetchProduct();
    },
    onChangeConfirmSubmit() {
      // if (this.confirmSubmit) {
      //   this.popupOnlineBillingActive = true;
      //   this.$store.commit("cart/checkRentalListInput", true);
      // }
       this.popupOnlineBillingActive = true;
      // this.$store.commit("cart/checkRentalListInput", true);
    },
    checkAvailableProduct(dateFormat) {
      let that = this;
      const url = `${this.$store.state.apiURL}/api/v2/CheckAvailableProduct`;

      if (this.cart.cartItems.length > 0) {
        this.cart.cartItems.forEach(item => {
          const data = {
            branchId: this.getCurrentBranch.branchId,
            productItemId: item.info.productItemId,
            startDate: `${dateFormat.start.year}-${dateFormat.start.month}-${dateFormat.start.day}`,
            endDate: `${dateFormat.end.year}-${dateFormat.end.month}-${dateFormat.end.day}`
          };

          axios.post(url, data, this.$store.state.auth.apiHeader).then(res => {
            const d = res.data;
            // console.log(d);

            if (!d.isAvailbleInPeriod) {
              // trigger product not available
              that.$store.dispatch("cart/triggerProductNotAvailable", {
                id: d.productItemId
              });
            }
          });
        });
      }
    },
    popupOnlineBilling() {
      const dateSelected = this.filter.date;
      if (dateSelected !== null) {
        // console.log(dateSelected);
        const startDate = new Date(dateSelected.start);
        const endDate = new Date(dateSelected.end);
        const dateFormat = {
          start: {
            year: startDate.getFullYear(),
            month: startDate.getMonth() + 1,
            day: startDate.getDate()
          },
          end: {
            year: endDate.getFullYear(),
            month: endDate.getMonth() + 1,
            day: endDate.getDate()
          }
        };

        this.checkAvailableProduct(dateFormat);
      }
    },
    modalExpandToggle() {
      if (this.modalExpand == "none") {
        this.modalExpand = "block";
      } else {
        this.modalExpand = "none";
      }
    },
    sliceIntoChunks(arr, chunkSize) {
      const res = [];
      for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        res.push(chunk);
      }
      return res;
    },
    async fetchAllInfo() {
      // const config = this.$store.state.auth.apiHeader;

      await  this.$store.dispatch("productGroup/getAllInfo");

      this.allInfo.categories = this.$store.getters["productGroup/categories"];
      this.allInfo.textures = this.$store.getters["productGroup/textures"];
      this.allInfo.colors =  this.$store.getters["productGroup/colors"];
      this.allInfo.sizes = this.$store.getters["productGroup/sizes"];

    },

    async fetchProduct() {
      const url = `${this.$store.state.apiURL}/api/v2/GetProductItems`;
      const config = this.$store.state.auth.apiHeader;

      let filterColor = "";
      let filterSize = "";


      this.filter.color.forEach(color => {
        filterColor += `${color.code}|`;
      });

      this.filter.size.forEach(size => {
        filterSize += `${size}|`;
      });

      let filterCategory = "";
      let filterTexture = "";

      if(this.filter.category !== null && this.filter.category !== ""){
        filterCategory = this.filter.category.code;
      }

      if(this.filter.texture !== null && this.filter.texture !== ""){
        filterTexture = this.filter.texture.code;
      }



      await axios
        .post(
          url,
          {
            filter: true,
            showNotAvailable: false,
            branchId: this.getCurrentBranch.branchId,
            simpleSearch: false,
            pageNumber: this.currentPaginate,
            pageSize: this.filter.displayItemSelected,
            color: filterColor,
            size: filterSize,
            category: filterCategory,
            texture: filterTexture,

          },

          config
        )
        .then(res => {
          const data = res.data;
          // console.log(`>>> online-billing mounted`);

          // get total items
          this.totalPaginate = Math.round(
            data.itemNumber / this.filter.displayItemSelected
          ) + 1;

          // fresh display items
          this.products = [];

          // update path image
          data.itemList.forEach((item, index) => {
            // console.log(item, index);
            if (data.itemList[index].mainImage !== null) {
              const imgURL = `${this.$store.state.imageStore}${item.mainImage}`;
              data.itemList[index]["mainImage"] = imgURL;
              // console.log(imgURL);
            } else {
              data.itemList[index]["mainImage"] = this.$store.state.defaultImage;

            }
          });

          this.products = data.itemList;


          this.pagination = this.$calcPagination(
            data.itemList.length,
            this.currentPaginate,
            data.itemListNumber,
            data.itemNumber
          );
        });
    }
  },
  watch: {

    windowWidth(newWidth, oldHWidth) {
            this.txt = `it changed to ${newWidth} from ${oldHWidth}`;
            this.widthText = newWidth.toString() + "px !important";
            console.log("this.widthText ****** ",this.widthText);
        },

    bailDiscount(){
      this.calculatePromotionDiscount();
    },

    async getCurrentBranch(){
        this.$vs.loading();
        this.checkRentalList = false;
        await this.fetchAllInfo();
        await this.fetchProduct();
        this.$vs.loading.close();
    },
    filter:{
       async handler(){
         this.startDate = this.filter.date.start;
         this.endDate = this.filter.date.end;
      },
      deep:true
    },

    cart: {
      handler(){
        // this.$store.commit("cart/checkRentalListInput", false);
        // this.$store.commit("cart/activeBtnInput", false);
        // console.log("checkRentalList >>> ",this.checkRentalList , " activeBtn >>> ",this.activeBtn);
      },
      deep:true
    },
    popupOnlineBillingActive(newvalue){
      if(newvalue == false){
        this.$store.commit("cart/checkRentalListInput", false);
        this.$store.commit("cart/activeBtnInput", false);
      }
        // console.log("WATCH:popupOnlineBillingActive ::: checkRentalList >>> ",this.checkRentalList , " activeBtn >>> ",this.activeBtn);

    },
    checkRentalList(newvalue){
      if(newvalue == true){
        this.popupOnlineBillingActive = true;
      }
      // console.log("WATCH:checkRentalList ::: checkRentalList >>> ",this.checkRentalList , " activeBtn >>> ",this.activeBtn);
    },
    async currentPaginate() {
      await this.fetchProduct();
    },
    async "filter.displayItemSelected"() {
      await this.fetchProduct();
    }
  },
  async mounted() {
    // console.log('page mounted')
    this.branchId = this.getCurrentBranch.branchId;
    await this.fetchAllInfo();
    await this.fetchProduct();
    // console.log("totalPaginate >>> ",this.totalPaginate);
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }
    // this.$nextTick(() => {
    //         window.addEventListener('resize', this.onResize);
    // });
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.vs__actions{
  display: none;
}

.w90px{
  width: 90% !important;
}



.btn-filter {
  margin-top: 17px;
}

.input-display-items input[readonly] {
  background-color: #f0f0f0 !important;
}

.product-catalog-table-detail td {
  font-size: 12px;
}

.card-footer .vs-card--content {
  margin-bottom: 0;
}

.multiselect__content-wrapper,
.multiselect__option {
  z-index: 9999 !important;
}

.vs-pagination--nav,
.vs-pagination--ul,
.item-pagination,
.vs-pagination--buttons {
  z-index: 1 !important;
}

.reduceBtnTrue{
  width: 32% !important;
  left: 68% !important;
}
.reduceBtnFalse{

 width: 28% !important;
  left: 72% !important;



}


@media only screen and (min-width:200px) and (max-width: 450) {
  .rental-date-validation-false {
    width:100% !important;
    top: 30%  !important;
  }
  .rental-date-validation-true {
       width:62.9% !important;
      top: 30% !important;
  }



}



.rental-date-validation-false {
    width:54.7% !important;
    top: 85% !important;
  }

  .rental-date-validation-true {
      width: 51.7% !important;
      top: 85% !important;
  }


@media only screen and (min-width: 1301px)  {

  .rental-date-validation-false {
      width: 52.7% !important;
      top: 82% !important;
  }
  .rental-date-validation-true {
      width: 61.2% !important;
      top: 82% !important;
  }
}

@media only screen and (min-width: 1101px) and (max-width: 1300px) {

  .rental-date-validation-false {
      width: 51.7% !important;
      top: 85% !important;
  }
  .rental-date-validation-true {
      width: 59.5% !important;
      top: 85% !important;
  }
}

@media only screen and (min-width:900px) and (max-width: 1100px) {
  .rental-date-validation-false {
    width:62.9% !important;
    top: 85%  !important;
  }
  .rental-date-validation-true {
       width:62.9% !important;
      top: 85% !important;
  }
}

@media only screen and (min-width:900px) and (max-width: 1100px) {
  .rental-date-validation-false {
    width:62.9% !important;
    top: 85%  !important;
  }
  .rental-date-validation-true {
       width:62.9% !important;
      top: 85% !important;
  }
}

/*

@media only screen and (min-width:450px)  {
    .cart-mobile{
      display: none !important;
    }
    .cart-lg{
      display: block !important;
  }


}

@media only screen and (min-width:200px) and (max-width: 450) {
  .cart-mobile{
      display: block !important;
  }

  .cart-lg{
      display: none !important;
  }




} */





</style>

<style lang="scss" scoped>

.scroll-area {
  position: relative;
  margin: auto;
  height: 1200px;
}

.cardx {
  box-shadow: none !important;
  border: 1px solid #f2f2f2;
}

.ef-btn-payment:active,
.ef-btn-payment:hover {
  box-shadow: none !important;
}

.c-box-shadow:hover {
  box-shadow: 2px 2px 2px 2px #f2f2f2 !important;
}
</style>
