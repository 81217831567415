<template>
  <div>
    <input
      class="vs-inputx vs-input--input normal"
      ref="input"
      v-bind:value="value"
      v-on:input="updateValue($event.target.value)"
      v-on:focus="selectAll"
      v-on:blur="formatValue"
    />
  </div>
</template>
<script>
export default {
  props: {
    value: {
      // type: Number,
      default: 0
    }
  },
  data() {
    return {
      welcome: "This is your profile"
    };
  },
  watch: {
    value: function(value) {
      this.formatValue(value);
    }
  },
  mounted: function() {
    this.formatValue();
  },

  methods: {
    updateValue: function(value) {
      // var result = currencyValidator.parse(value, this.value);
      // if (result.warning) {
      //   this.$refs.input.value = result.value;
      // }
      // this.$emit("input", result.value);
      this.$emit("input", value);
    },
    formatValue: function() {
      this.$refs.input.value = this.formatPrice(this.value);
    },
    formatPrice(value) {
      // var number = +value.replace(/[^\d.]/g, "");
      value = this.convertToNum(value);
      var amount = isNaN(value) ? 0 : value;
      return amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    convertToNum(value) {
      if (typeof value === "string") {
        value = Number(value.replace(",", ""));
      }
      if (isNaN(value)) {
        value = 0;
      }
      return value;
    },
    selectAll: function(event) {
      // Workaround for Safari bug
      // http://stackoverflow.com/questions/1269722/selecting-text-on-focus-using-jquery-not-working-in-safari-and-chrome
      setTimeout(function() {
        event.target.select();
      }, 0);
    }
  },
  components: {}
};
</script>

<style >
</style>
